@import 'variables';
@import 'mixins';

.sticky-container {
  position: relative;

  .top-offset {
    top: 0;
    padding-top: 34px;
    height: 100%;
  }
}

.left-area {
  .rs-card {
    > div {
      > p {
        color: var(--rs-color--grey--500);
        @include rs-font--primary-light;
      }
    }
  }
}
